import React from 'react';
import styled from 'styled-components';
import { getCleanProps } from './answer-tools';
import { validateAll } from './validate-question';

let iframeStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
};

var Hustlegraphic = styled(
  class extends React.Component {
    state = {
      mounted: false,
      graphicMade: false,
      iFrameLoaded: false,
    };
    iFrameRef = React.createRef();
    makeGraphic(cleanProps) {
      if (this.state.iFrameLoaded && this.iFrameRef.current) {
        let iFrameWindow = this.iFrameRef.current.contentWindow;
        iFrameWindow.hustleGraphic.makeGraphic(cleanProps);
        if (this.props.setCanvas) {
          this.props.setCanvas(iFrameWindow.document.getElementById('canvas'));
        }
        this.setState({ graphicMade: true });
      }
    }
    componentDidMount() {
      this.setState({ mounted: true });
      const iFrame = this.iFrameRef.current;
      iFrame.onload = () => {
        this.setState({ iFrameLoaded: true });
      };
    }
    componentDidUpdate(lastProps, _lastState) {
      if (
        this.state.mounted &&
        !this.state.graphicMade &&
        this.state.iFrameLoaded
      ) {
        let props = {
          info: this.props.info,
          answers: this.props.answers,
          questions: this.props.questions,
        };
        let errors = validateAll(props);
        if (errors.length === 0) {
          let cleanProps = getCleanProps(props);
          this.makeGraphic(cleanProps);
        } else {
        }
      }
      if (this.props !== lastProps) {
      } else {
      }
    }
    render() {
      let numQuestions =
        getCleanProps({
          info: this.props.info,
          answers: this.props.answers,
          questions: this.props.questions,
        }).questions.length || 0;
      let wrapClass = ' height-';
      if (numQuestions <= 2) {
        wrapClass += '951';
      } else if (
        numQuestions <= 4 ||
        (numQuestions >= 7 && numQuestions <= 9)
      ) {
        wrapClass += '1611';
      } else if (numQuestions >= 5 && numQuestions <= 6) {
        wrapClass += '1171';
      }
      const { updateImageLink, questions, info, answers, setCanvas, ...props } =
        this.props;
      return (
        <div {...props} className={props.className + wrapClass}>
          <iframe
            title="hustlegraphic"
            ref={this.iFrameRef}
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="100%"
            style={iframeStyle}
            src="/hustlegraphic_animations/static"
          />
        </div>
      );
    }
  }
)`
  position: relative;
  width: 100%;
  padding-bottom: ${100 * (4 / 3)}%;
  &.height-951 {
    padding-bottom: ${100 * (951 / 1536)}%;
  }
  &.height-1171 {
    padding-bottom: ${100 * (1171 / 1536)}%;
  }
  &.height-1611 {
    padding-bottom: ${100 * (1611 / 1536)}%;
  }
`;

export default Hustlegraphic;
