import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonText } from '../lib/components/buttons';
import styled from 'styled-components';
import isIOS from '../lib/ios-detect';
import { withRouter } from 'react-router';
import { store } from '../app';
import { updateImageLink } from '../reducers/hustlegraphics';
import Analytics, { GA_CATEGORY_HUSTLEGRAPHIC } from '../analytics';

const Wrap = styled.span`
  display: inline-block;
`;

class DownloadImage extends React.Component {
  constructor(props) {
    super(props);
    const state = {
      supportsDownload: false,
      startDownload: false,
    };
    if (
      typeof document !== 'undefined' &&
      typeof document.createElement('a').download !== 'undefined'
    ) {
      state.supportsDownload = true;
    }
    this.state = state;
    this.save = this.save.bind(this);
  }
  componentDidUpdate(lastProps, lastState) {
    if (this.state.startDownload) {
      this.setState({ startDownload: false, action: 'Share' });
      Analytics.event({
        category: GA_CATEGORY_HUSTLEGRAPHIC,
        action: 'Save Image',
      });
      if (isIOS) {
        Analytics.event({
          category: GA_CATEGORY_HUSTLEGRAPHIC,
          action: 'Share Start',
          label: 'Download iOS',
        });
        this.props.history.push('/hustlegraphic/download-ios');
      } else {
        Analytics.event({
          category: GA_CATEGORY_HUSTLEGRAPHIC,
          action: 'Share Complete',
          label: 'Download',
        });
        this.refs.form.submit();
      }
    }
  }
  save(e) {
    e.preventDefault();
    store.dispatch(updateImageLink(this.props.canvas));
    this.setState({ startDownload: true });
  }
  render() {
    let dataURL = this.props.dataURL || '';
    return (
      <Wrap>
        <Button to="" onClick={this.save}>
          <ButtonText>Save image</ButtonText>
        </Button>
        <form ref="form" method="POST" action="/api/download">
          <input type="hidden" name="imageData" value={dataURL} />
          <input type="hidden" name="filename" value="hustlegraphic.png" />
        </form>
      </Wrap>
    );
  }
}

DownloadImage.propTypes = {
  children: PropTypes.node,
  dataURL: PropTypes.string,
  matText: PropTypes.string,
};

DownloadImage.defaultProps = {};

export default withRouter(DownloadImage);
