import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  fall: {
    ...baseStyle,
    isNew: true,
    name: 'Fall',
    thumbnail: '/images/mats/mat_fall-thumb.jpg',
    bgImage: '/images/mats/mat_fall-bg.jpg',
    overlayImage: '/images/mats/mat_fall-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'korolev, Futura, sans-serif',
      spacing: 0.25,
      pos: { x: 0.5, y: 683 / 1056 },
      maxWidth: 656 / 1280,
      fontColor: '#0e0b08',
      fontSize: 47.5 / 1280,
      fontWeight: '700',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: 907 / 1056,
      },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: (907 + 48) / 1056,
      },
    },
  },
};

export default mats;
