import React from 'react';
import { Dropdown } from '../lib/components/dropdown';

export default (props) => {
  let options = [
    {
      id: 'treat-select-candy',
      value: 'candy',
      label: 'Candy',
    },
    {
      id: 'treat-select-cookies',
      value: 'cookies',
      label: 'Cookies',
    },
    {
      id: 'treat-select-donuts',
      value: 'donuts',
      label: 'Donuts',
    },
    {
      id: 'treat-select-croissants',
      value: 'croissants',
      label: 'Croissants',
    },
  ];
  return (
    <div>
      <Dropdown
        tabIndex={props.tabIndex || ''}
        className="main-input"
        name="treat-select"
        placeholder="Select a treat..."
        value={props.answer && props.answer.treat ? props.answer.treat : ''}
        options={options}
        onChange={(value) => props.setAnswer({ treat: value })}
      />
    </div>
  );
};
