import React from 'react';
import MyInput from './answer-input';

export default class extends React.Component {
  render() {
    return (
      <div>
        <MyInput
          tabIndex={this.props.tabIndex || ''}
          className="main-input"
          value={this.props.answer ? this.props.answer.number : ''}
          type="number"
          min="0"
          step="1"
          pattern="[0-9]"
          onChange={(e) => {
            this.props.setAnswer({ number: e.currentTarget.value });
          }}
        />
      </div>
    );
  }
}
