// @flow
import React from 'react';
import styled from 'styled-components';
import { FullWidthButton, SkipButton } from '../lib/components/buttons';
import validateQuestion from './validate-question';
import { CSSTransitionGroup } from 'react-transition-group';
import Analytics, { GA_CATEGORY_HUSTLEGRAPHIC } from '../analytics';
// import { Question as QuestionType } from './questions-data';
// import { Answer as AnswerType } from '../reducers/hustlegraphics';

const borderRadius = `8px`;

const CornerTagSm = styled.span`
  font-size: 10px;
  font-style: italic;
`;
const CornerTag = styled((props) => {
  let { numQuestions, questionNumber, ...p } = props;
  return (
    <div {...p}>
      {props.questionNumber}
      <CornerTagSm> of </CornerTagSm>
      {props.numQuestions || 12}
    </div>
  );
})`
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-color: ${(props) => props.theme.secondaryColorLight};
  padding: 4px 10px 2px 10px;
  border-radius: ${borderRadius} 0 ${borderRadius} 0;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const EditTagIcon = styled.img`
  height: 100%;
  width: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  * {
    pointer-events: none;
  }
`;
const EditTag = styled((props) => {
  return (
    <div {...props}>
      <EditTagIcon src="/images/icon-edit.png" />
      Edit
    </div>
  );
})`
  cursor: pointer;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 14px;
  font-weight: 500;
  color: white;
  letter-spacing: 0.08em;
  height: 26px;
  padding-left: 30px;
  padding-right: 10px;
  line-height: 26px;
  border-radius: 0 ${borderRadius} 0 ${borderRadius};
  background-color: rgba(255, 9, 0, 0.7);
  &:hover {
    opacity: 1;
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

const QuestionText = styled.div`
  font-family: ${(props) => props.theme.fontPrimary};
  font-weight: 500;
  font-size: 19px;
  font-style: normal;
  line-height: 1.3em;
  vertical-align: bottom;
  display: table-cell;
  padding-bottom: 10px;
  width: 100%;
`;

const QuestionSubmit = styled((props) => (
  <FullWidthButton {...props}>Play animation</FullWidthButton>
))`
  margin-top: 5px;
`;
const QuestionInner = styled.div`
  display: table;
  filter: blur(${(props) => (props.defocus ? 1.5 : 0)}px);
`;
const QuestionTop = styled.div`
  display: table-row;
  height: 109px;
  width: 100%;
`;
const QuestionBottom = styled.div`
  display: table-row;
  width: 100%;
`;

const Answer = styled(
  class extends React.Component {
    // props: {
    //   question: QuestionType;
    //   answer: AnswerType;
    //   setDataURL: (string) => void;
    // };
    state = {
      hasFocus: false,
      isLoaded: false,
    };
    iFrameRef = React.createRef();
    componentDidMount() {
      const iFrame = this.iFrameRef.current;
      iFrame.onload = () => {
        let iFrameWindow = iFrame.contentWindow;
        iFrameWindow.hustleGraphic.playAnim({
          sectionName: this.props.question.animationName,
          ...this.props.answer,
        });
        iFrameWindow.hustleGraphic.setDataURL = this.props.setDataURL;
        if (iFrameWindow.hustleGraphic.dataURL) {
          this.props.setDataURL(iFrameWindow.hustleGraphic.dataURL);
        }
        this.setState({ isLoaded: true });
      };
    }
    render() {
      let { question, answer, setDataURL, ...divProps } = this.props;
      return (
        <div {...divProps}>
          <iframe
            title={`answer_${question.id}`}
            style={{ visibility: this.state.isLoaded ? 'visible' : 'hidden' }}
            ref={this.iFrameRef}
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="100%"
            src={question.animationPath}
          />
        </div>
      );
    }
  }
)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: ${borderRadius};
  box-sizing: content-box;
  overflow: hidden;
  background-color: transparent;
`;

const Question = styled(
  class extends React.Component {
    state = {
      showAnswer: false,
      dataURL: '',
      skip: false,
    };
    // props: {
    //   gotoNextQuestion: () => void,
    //   question: QuestionType,
    //   answer: AnswerType,
    //   onSkip: () => void,
    //   setAnswer: (string, Answer) => void,
    //   questionNumber: 1,
    //   defocus: false,
    //   hasFocus: false,
    // };
    static defaultProps = {
      hasFocus: false,
    };
    onClickPlay = (e) => {
      e.preventDefault();
      Analytics.event({
        category: GA_CATEGORY_HUSTLEGRAPHIC,
        action: 'Play Animation',
        label: this.props.question.analyticsLabel,
      });
      this.showAnswer();
      this.props.gotoNextQuestion();
    };
    showAnswer = (e) => {
      if (validateQuestion(this.props.question.id, this.props.answer)) {
        this.setState({ showAnswer: true, dataURL: '' });
      }
    };
    componentWillUpdate(nextProps, nextState) {
      if (!this.props.hasFocus && nextProps.hasFocus) {
      }
      if (
        !this.state.showAnswer &&
        this.props.hasFocus &&
        !nextProps.hasFocus
      ) {
        this.showAnswer();
      }
      if (!this.state.skip && nextState.skip) {
        this.props.onSkip();
      }
    }
    setAnswer = (answer) => {
      this.props.setAnswer(this.props.question.id, answer);
    };
    skipQuestion = (e) => {
      try {
        e.preventDefault();
      } catch (e) {}
      this.setState({ skip: true, showAnswer: false, dataURL: '' });
      this.props.setAnswer(this.props.question.id, null);
    };
    unskipQuestion = (e) => {
      try {
        e.preventDefault();
      } catch (e) {}
      this.setState({ skip: false });
    };
    setDataURL = (dataURL) => {
      this.setState({ dataURL: dataURL });
    };
    render() {
      const {
        question,
        questionNumber,
        answer,
        setAnswer,
        defocus,
        gotoNextQuestion,
        hasFocus,
        onSkip,
        ...p
      } = this.props;
      let showAnswer = this.state.showAnswer;
      let showCached =
        this.state.dataURL &&
        typeof this.state.dataURL === 'string' &&
        this.state.dataURL !== '';
      showCached = false;

      let QuestionContent = (
        <div>
          <question.component
            setAnswer={this.setAnswer}
            answer={answer}
            tabIndex={this.props.questionNumber * 10}
          />
          <QuestionSubmit
            onClick={this.onClickPlay}
            disabled={
              !validateQuestion(this.props.question.id, this.props.answer)
            }
          />
          <SkipButton onClick={this.skipQuestion}>Skip</SkipButton>
        </div>
      );

      let SkipContent = (
        <div>
          <FullWidthButton
            style={{ marginTop: '30px' }}
            onClick={this.unskipQuestion}
          >
            Unskip&nbsp;question
          </FullWidthButton>
        </div>
      );

      return (
        <div {...p}>
          <div className="question-inner">
            <QuestionPart className="question-part">
              <QuestionInner className="focus-target">
                <QuestionTop>
                  <QuestionText>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: question.questionText,
                      }}
                    />
                  </QuestionText>
                </QuestionTop>
                <QuestionBottom>
                  {this.state.skip ? SkipContent : QuestionContent}
                </QuestionBottom>
              </QuestionInner>
              <CornerTag questionNumber={questionNumber} numQuestions={12} />
            </QuestionPart>
            <CSSTransitionGroup
              transitionEnter={false}
              transitionLeaveTimeout={400}
              transitionName="transition"
            >
              {showAnswer && showCached ? (
                <img
                  alt="Question answer"
                  key="answer-image"
                  width="100%"
                  height="100%"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  src={this.state.dataURL}
                />
              ) : (
                ''
              )}
              {showAnswer && !showCached ? (
                <Answer
                  key={'answer' + question.id}
                  setDataURL={this.setDataURL}
                  question={question}
                  answer={answer}
                />
              ) : (
                ''
              )}
            </CSSTransitionGroup>
            {showAnswer ? (
              <EditTag
                key="edit-tag"
                onClick={() => {
                  this.setState({ showAnswer: false });
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      );
    }
  }
)`
  .transition-leave.transition-leave-active {
    opacity: 0;
    transition: all 0.15s linear;
  }
  .transition-leave {
    pointer-events: none;
    * {
      pointer-events: none !important;
    }
    opacity: 1;
  }
  .question-inner {
    position: relative;
  }
  position: relative;
  background-color: #82c5e7;
  border-radius: ${borderRadius};
  overflow: hidden;
  height: 258px;
  width: 300px;
`;

const QuestionPart = styled.div`
  position: relative;
  color: white;
  display: block;
  text-align: center;
  background-color: #82c5e7;
  height: 258px;
  width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
`;

export default Question;
