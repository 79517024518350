import quirky from './mats-data/mat-quirky';
import suburban from './mats-data/mat-suburban';
import family from './mats-data/mat-family';
import country from './mats-data/mat-country';
import generic from './mats-data/mat-generic';
import sports from './mats-data/mat-sports';
import fall from './mats-data/mat-fall';
import winter from './mats-data/mat-winter';
import holidayComeIn from './mats-data/mat-holiday-come-in';
import holidayHappyHolidays from './mats-data/mat-holiday-happy-holidays';
import winterPlayful from './mats-data/mat-winter-playful';
import winterWarm from './mats-data/mat-winter-warm';
import { Mats } from './mats-data/mat-data-type';

const mats: Mats = {
  ...fall,
  ...holidayComeIn,
  ...holidayHappyHolidays,
  ...winter,
  ...winterPlayful,
  ...winterWarm,
  ...quirky,
  ...suburban,
  ...family,
  ...country,
  ...generic,
  ...sports,
};

export default mats;
