import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'holiday-come-in': {
    ...baseStyle,
    isNew: true,
    name: 'Holiday Come In',
    thumbnail: '/images/mats/mat_holiday_come_in-thumb.jpg',
    bgImage: '/images/mats/mat_holiday_come_in-bg.jpg',
    overlayImage: '/images/mats/mat_holiday_come_in-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'superclarendon, Georgia, serif',
      spacing: 0.22,
      pos: { x: 0.5, y: 705 / 1056 },
      maxWidth: 820 / 1280,
      fontColor: '#fff',
      fontSize: 29 / 1280,
      fontWeight: 'bold',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: 907 / 1056,
      },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: (907 + 48) / 1056,
      },
    },
  },
};

export default mats;
