import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import './index.css';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Main from './main';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import IntroPage from './intro-page';
import WelcomeMats from './welcome-mat/routes';
import Hustlegraphics from './hustlegraphic/routes';
import Analytics from './analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const LogPageViews = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    Analytics.set({ page: pathname });
    Analytics.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return null;
};

const Routes = (props) => {
  return (
    <BrowserRouter {...props}>
      <ScrollToTop />
      <LogPageViews />
      <Main>
        <Switch>
          <Route path="/welcome-mat" component={WelcomeMats} {...props} />
          <Route path="/hustlegraphic" component={Hustlegraphics} {...props} />
          <Route exact path="/" component={IntroPage} {...props} />
          <Redirect from="*" to="/" />
        </Switch>
      </Main>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Routes />
  </ThemeProvider>,
  document.getElementById('root')
);
