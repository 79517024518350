import {
  COMMERCIAL_LUXURY,
  EXPERIENCE,
  HOMES_BOUGHT,
  HOMES_SOLD,
  LUNCHES,
  MILES,
  MIRACLE_HOMES,
  OPEN_HOUSES,
  TREAT,
  COFFEE,
  SONG,
  NEIGHBORHOODS,
} from '../reducers/hustlegraphics';
import AnswerTreat from './answer-treat';
import AnswerCommercialLuxury from './answer-commercial-luxury';
import AnswerNumber from './answer-number';
import AnswerSong from './answer-song';

const GENERIC_ANIMATION_PATH = '/hustlegraphic_animations/generic/index.html';

export type Question = {
  id: string;
  questionText: string;
  animationPath: string;
  animationName: string;
  component: any;
  analyticsLabel: string;
};

const data: Array<Question> = [
  {
    id: HOMES_SOLD,
    questionText: 'How many homes did you&nbsp;sell?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'homes_sold',
    component: AnswerNumber,
    analyticsLabel: 'Homes Sold',
  },
  {
    id: HOMES_BOUGHT,
    questionText: 'How many homes did you help&nbsp;buy?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'homes_bought',
    component: AnswerNumber,
    analyticsLabel: 'Homes Bought',
  },
  {
    id: COMMERCIAL_LUXURY,
    questionText:
      'How many commercial&#8203;/&#8203;luxury properties did you buy/sell?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'luxury_commercial',
    component: AnswerCommercialLuxury,
    analyticsLabel: 'Commercial.Collection Properties',
  },
  {
    id: NEIGHBORHOODS,
    questionText: 'How many neighborhoods have you worked?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'neighborhoods',
    component: AnswerNumber,
    analyticsLabel: 'Neighborhoods',
  },
  {
    id: EXPERIENCE,
    questionText: 'How many years of experience do you&nbsp;have?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'experience',
    component: AnswerNumber,
    analyticsLabel: 'Years of Experience',
  },
  {
    id: OPEN_HOUSES,
    questionText: 'How many open houses did you&nbsp;host?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'open_houses',
    component: AnswerNumber,
    analyticsLabel: 'Open Houses',
  },
  {
    id: SONG,
    questionText: 'What’s your favorite song to hustle&nbsp;to?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'song',
    component: AnswerSong,
    analyticsLabel: '# Hustle Song',
  },
  {
    id: MILES,
    questionText: 'How many miles did you&nbsp;drive?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'miles',
    component: AnswerNumber,
    analyticsLabel: 'Miles Driven',
  },
  {
    id: LUNCHES,
    questionText: 'How many lunches did you eat in your&nbsp;car?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'lunches',
    component: AnswerNumber,
    analyticsLabel: 'Car Lunches',
  },
  {
    id: MIRACLE_HOMES,
    questionText: 'How many Miracle Home properties have you&nbsp;sold?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'miracle_homes',
    component: AnswerNumber,
    analyticsLabel: 'Miracle Homes Sold',
  },
  {
    id: TREAT,
    questionText: 'Choose your favorite open house&nbsp;treat:',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'treat',
    component: AnswerTreat,
    analyticsLabel: 'Treat',
  },
  {
    id: COFFEE,
    questionText: 'How many cups of coffee do you drink every&nbsp;week?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'coffee',
    component: AnswerNumber,
    analyticsLabel: 'Coffee',
  },
];

export default data;
