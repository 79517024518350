// @flow
import { COMMERCIAL_LUXURY, SONG, TREAT } from '../reducers/hustlegraphics';
import {
  Answer,
  AnswerCommercialLuxury,
  AnswerSong,
  AnswerNumber,
  AnswerTreat,
  Answers,
  Info,
} from '../reducers/hustlegraphics';
import remaxTextFilter from '../lib/remax-text-filter';

export type Error = {
  message: string;
  fieldName?: string;
};
export type Errors = Array<Error>;

export default function validateAnswer(id: string, answer: Answer) {
  if (!answer) {
    return false;
  }
  switch (id) {
    case COMMERCIAL_LUXURY:
      return (
        (answer as AnswerCommercialLuxury).type &&
        (answer as AnswerCommercialLuxury).type !== '' &&
        (answer as AnswerCommercialLuxury).number &&
        Number((answer as AnswerCommercialLuxury).number) > 0
      );
    case SONG: {
      let filteredTitle = remaxTextFilter(
        (answer as AnswerSong).songTitle || ''
      );
      return filteredTitle && filteredTitle !== '';
    }
    case TREAT:
      return (
        (answer as AnswerTreat).treat && (answer as AnswerTreat).treat !== ''
      );
    default:
      return (
        (answer as AnswerNumber).number &&
        Number((answer as AnswerNumber).number) > 0
      );
  }
}

type ValidationInfo = {
  answers: Answers;
  info: Info;
};
const defaultInfo = {
  agentName: '',
  email: '',
  phoneNumber: '',
  officeName: '',
};
export const validateAll = ({
  answers = {},
  info = defaultInfo,
}: ValidationInfo) => {
  let errors: Errors = [];
  errors = errors.concat(validateAnswers(answers));
  errors = errors.concat(validateInfo(info));
  return errors;
};

export const validateAnswers = (answers: Answers) => {
  let errors: Errors = [];

  let questionsAnswered = 0;
  for (let answerID in answers) {
    if (validateAnswer(answerID, answers[answerID])) {
      questionsAnswered++;
    }
  }
  if (questionsAnswered <= 0) {
    errors = errors.concat({
      message: 'You must answer at least one question.',
    });
  }
  return errors;
};

export const validateInfo = (info: Info = defaultInfo) => {
  let errors: Errors = [];
  if (info.agentName.length <= 0) {
    errors.push({ fieldName: 'agentName', message: 'Agent name is required.' });
  }
  if (info.officeName.length <= 0) {
    errors.push({
      fieldName: 'officeName',
      message: 'Office name is required.',
    });
  }
  if (info.phoneNumber.length <= 0) {
    errors.push({
      fieldName: 'phoneNumber',
      message: 'Phone number is required.',
    });
  } else if (info.phoneNumber.length < 10) {
    errors.push({
      fieldName: 'phoneNumber',
      message: 'Phone number must be at least 10 digits long.',
    });
  }
  return errors;
};
