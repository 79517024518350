import React from 'react';
import MyInput from './answer-input';
import remaxTextFilter from '../lib/remax-text-filter';

export default (props) => (
  <div>
    <MyInput
      tabIndex={props.tabIndex || ''}
      className="main-input"
      value={props.answer ? props.answer.songTitle : ''}
      onChange={(e) => props.setAnswer({ songTitle: e.currentTarget.value })}
      onBlur={(e) => {
        props.setAnswer({ songTitle: remaxTextFilter(e.currentTarget.value) });
      }}
    />
  </div>
);
