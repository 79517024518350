import PropTypes from 'prop-types';

import React from 'react';
// import { connect, Provider } from 'react-redux';
import matsData from './mats-data';
import { store } from '../app';
import { setMatType } from '../reducers/welcome-mats';
import pageData, { PAGE_WELCOME_MAT_CUSTOMIZE } from './page-data';
import { Provider, connect } from 'react-redux';
import { PageHead } from '../lib/components/page-head';
import Link from '../lib/components/link';
import styled from 'styled-components';
import { PageContentWithHeader } from '../lib/components/page-content';
import find from 'lodash.find';

var matOrder = [
  'fall',
  'holiday-come-in',
  'holiday-happy-holidays',
  'winter',
  'winter-playful',
  'winter-warm',
  'quirky-wh',
  'quirky-hsh',
  'suburban-wh',
  'suburban-hsh',
  'family-wh',
  'family-hsh',
  'country-wh',
  'country-hsh',
  'generic-wh',
  'generic-hsh',
  'sports-wh',
  'sports-hsh',
];

const MatListWrap = styled.ul`
  width: auto;
  margin: 0;
  margin-top: -5px;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const MatListItemLink = styled(Link)`
  display: block;
`;

const MatListItem = styled((props) => {
  let { href, onClick, ...liProps } = props;
  return (
    <li {...liProps}>
      <MatListItemLink to={href} onClick={onClick}>
        {props.children}
      </MatListItemLink>
    </li>
  );
})`
  list-style-type: none;
  position: relative;
  padding: 5px;
  float: left;
  width: 50%;
`;

const MatListItemWide = styled(MatListItem)`
  width: 50%;
  ${(props) => props.theme.mq1} {
    width: 50%;
  }
  ${(props) => props.theme.mq3} {
    width: 25%;
  }
`;

const MatImgOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;
const MatImgOverlayHide = styled(MatImgOverlay)`
  display: none;
`;
const MatImg = styled.img``;
const MatImgSelected = styled.img`
  outline: 3px solid ${(p) => p.theme.secondaryColor};
`;
const MatListImg = styled((p) => {
  let { showSelection, selected, isNew, ...props } = p;
  let Img = MatImg;
  let ImgOverlay = MatImgOverlayHide;
  if (showSelection) {
    if (selected) {
      Img = MatImgSelected;
    } else {
      ImgOverlay = MatImgOverlay;
    }
  }
  return (
    <div {...props} key={props.key}>
      <Img {...props} />
      <ImgOverlay />
      {isNew ? <NewTag /> : null}
    </div>
  );
})`
  position: relative;
`;

const NewTag = styled((p) => (
  <img className="something" {...p} alt="New" src="/images/new-tag.png" />
))`
  position: absolute;
  bottom: 5%;
  right: -6px;
  height: 15%;
  min-height: 20px;
  max-height: 42px;
  width: auto;
`;

MatListImg.propTypes = {
  showSelection: PropTypes.bool,
};

class MatList extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.renderMat = this.renderMat.bind(this);
  }
  onClick(e, idx) {
    if (!this.props.isPage) {
      e.preventDefault();
    }
    this.props.setMatType(idx);
  }
  renderMat(mat, key) {
    let href = '#';
    let Item = MatListItem;
    if (this.props.isPage) {
      Item = MatListItemWide;
    }
    if (this.props.nextPage) {
      href = '/welcome-mat/personalize';
    }
    return (
      <Item key={key} href={href} onClick={(e) => this.onClick(e, key)}>
        <MatListImg
          key={key}
          showSelection={!this.props.isPage}
          selected={this.props.matID === key}
          alt={mat.name}
          src={mat.thumbnail}
          isNew={mat.isNew}
        />
      </Item>
    );
  }
  render() {
    const mats = matOrder.map((key) => this.renderMat(matsData[key], key));

    return <MatListWrap className="mat-list">{mats}</MatListWrap>;
  }
}

MatList.propTypes = {
  setMatType: PropTypes.func,
};

MatList.defaultProps = {};

function mapStateToProps(state, ownProps) {
  return {
    ...{},
    ...ownProps,
    matID: state.matData.matID,
  };
}

const ReduxContainer = connect(mapStateToProps, {
  setMatType,
})(MatList);

const MatListContainer = (props) => (
  <Provider store={store}>
    <ReduxContainer {...props} />
  </Provider>
);
export default MatListContainer;

export class MatSelectPage extends React.Component {
  render() {
    const nextPage = find(pageData, { id: PAGE_WELCOME_MAT_CUSTOMIZE });
    return (
      <PageContentWithHeader>
        <PageHead>Select a mat that best fits their style</PageHead>
        <MatListContainer isPage={true} nextPage={nextPage} {...this.props} />
      </PageContentWithHeader>
    );
  }
}
