import PropTypes from 'prop-types';
import React from 'react';
import questionsData from './questions-data';
import styled from 'styled-components';
import Question from './question';
import $ from 'jquery';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { isMobileWidth } from '../viewport';
import { TweenMax, Power1 } from 'gsap';
// eslint-disable-next-line
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import theme from '../theme';

const QuestionListWrap = styled.ul`
  width: auto;
  margin: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 20px;
  ${(props) => props.theme.questionMQ2} {
    margin-left: -10px;
    margin-right: -10px;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

const QuestionListItem = styled(
  class extends React.Component {
    state = {
      mouseOver: false,
    };
    // props: {
    //   question: Question;
    //   questionNumber: number;
    //   answer: Answer;
    //   setAnswer: (string, Answer) => void;
    //   className: string;
    //   hasFocus: boolean;
    //   takeFocus: (any) => void;
    // };
    gotoNextQuestion = () => {
      if (isMobileWidth()) {
        TweenMax.to(window, 0.25, {
          scrollTo: {
            y: ReactDOM.findDOMNode(this),
            offsetY: theme.headerHeight + theme.margin,
          },
          ease: Power1.easeInOut,
        });
      }
      this.props.takeFocus(this.props.questionNumber + 1);
    };
    onSkip = () => {
      if (isMobileWidth()) {
        TweenMax.to(window, 0.5, {
          scrollTo: {
            y: $(ReactDOM.findDOMNode(this)).next().get(0),
            offsetY: theme.headerHeight + theme.margin,
          },
          ease: Power1.easeInOut,
        });
      }
      this.props.takeFocus(this.props.questionNumber + 1);
    };
    componentDidUpdate(lastProps, lastState) {}
    render() {
      let {
        question,
        questionNumber,
        answer,
        setAnswer,
        className,
        hasFocus,
        takeFocus,
        ...props
      } = this.props;
      return (
        <li
          {...props}
          className={classNames(className, 'question-wrap', {
            defocus: !hasFocus && !this.state.mouseOver,
            focus: hasFocus,
          })}
          onFocus={(e) => {
            takeFocus && takeFocus(questionNumber);
          }}
          onMouseDown={(e) => {
            takeFocus && takeFocus(questionNumber);
          }}
          onMouseEnter={(e) => {
            this.setState({ mouseOver: true });
          }}
          onMouseLeave={(e) => {
            this.setState({ mouseOver: false });
          }}
        >
          <Question
            hasFocus={hasFocus}
            onSkip={this.onSkip}
            gotoNextQuestion={this.gotoNextQuestion}
            className="question"
            question={question}
            questionNumber={questionNumber}
            answer={answer}
            setAnswer={setAnswer}
          />
        </li>
      );
    }
  }
)`
  position: relative;
  list-style-type: none;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
  float: left;
  width: 300px;
  height: 258px;
  &.defocus {
    .focus-target {
      // filter: blur(.75px);
    }
    .question-part {
      // background-color: #a8d7ee;
      filter: contrast(35%) brightness(145%) saturate(130%);
    }
  }
  &.focus {
    .question {
      border: 3px solid ${(props) => props.theme.secondaryColorLight};
      margin: -3px;
      width: 306px;
      height: 264px;
      border-radius: 12px;
    }
  }
  ${(props) => props.theme.questionMQ1} {
    width: 50%;
    &:nth-child(2n + 1) {
      clear: both;
    }
    &.defocus {
      .focus-target {
        filter: none;
      }
    }
  }
  ${(props) => props.theme.questionMQ2} {
    width: 33.333333333333%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
    &:nth-child(2n + 1) {
      clear: none;
    }
    &:nth-child(3n + 1) {
      clear: both;
    }
  }
`;

export class QuestionList extends React.Component {
  state = {
    focusedQuestion: 1,
  };
  // props: {
  //   answers: Answers,
  //   setAnswer: (string, Answer) => void,
  // };
  takeFocus = (questionNumber) => {
    this.setState({ focusedQuestion: questionNumber });
  };
  scrollHandler = (e) => {};
  componentDidMount() {
    window.addEventListener('scroll', this.scrollHandler);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
  }
  renderQuestion = (question, key) => {
    const questionNumber = key + 1;

    return (
      <QuestionListItem
        hasFocus={questionNumber === this.state.focusedQuestion}
        takeFocus={this.takeFocus}
        key={key}
        question={question}
        questionNumber={key + 1}
        answer={this.props.answers[question.id]}
        setAnswer={this.props.setAnswer}
      />
    );
  };
  render() {
    return (
      <QuestionListWrap className="mat-list">
        {questionsData.map(this.renderQuestion)}
      </QuestionListWrap>
    );
  }
}

QuestionList.propTypes = {
  setQuestionType: PropTypes.func,
  setAnswer: PropTypes.func,
  answers: PropTypes.object,
};

QuestionList.defaultProps = {};
