import React from 'react';
import styled from 'styled-components';
import { BasicInput } from './input';
import find from 'lodash.find';

const DropdownIcon = styled((props) => (
  <div {...props}>
    <img src="/images/dropdown-arrow.png" alt="down arrow" />
  </div>
))`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 27px;
  height: 21px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.secondaryColorLight};
  img {
    height: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface DropdownOptionProps extends React.HTMLProps<HTMLInputElement> {}

const DropdownInput = styled((props: DropdownOptionProps) => (
  <input type="radio" {...props} />
))`
  position: absolute;
  opacity: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
`;
interface DropdownButtonProps {
  value: string;
  placeholder: string | undefined;
  tabIndex: number | undefined;
  [x: string]: any;
}
const DropdownButton = styled(
  ({ value, placeholder, tabIndex, ...p }: DropdownButtonProps) => {
    return (
      <div {...p}>
        <BasicInput
          tabIndex={tabIndex}
          placeholder={placeholder}
          style={{ pointerEvents: 'none' }}
          value={value}
          onChange={(e: any) => {}}
        />
        <DropdownIcon />
      </div>
    );
  }
)`
  cursor: pointer;
  position: relative;
  > * {
    pointer-events: none;
  }
`;
const DropdownOptionLabel = styled.label`
  width: 100%;
  height: 34px;
  line-height: 34px;
  display: block;
  user-select: none;
`;

const DropdownOption = styled((props: DropdownOptionProps) => {
  const { name, value, children, id, checked, onChange, onClick } = props;
  return (
    <div className={props.className} onClick={onClick}>
      <DropdownInput
        className="dropdownInput"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <DropdownOptionLabel htmlFor={id}>{children}</DropdownOptionLabel>
    </div>
  );
})`
  font-family: ${(props) => props.theme.fontPrimary};
  width: 100%;
  :hover {
    background-color: ${(props) => props.theme.secondaryColorLight};
    color: white;
  }
  & .dropdownInput {
    background-color: red;
  }
`;
const DropdownOptions = styled((props: any) => {
  return <div {...props}>{props.children}</div>;
})`
  position: absolute;
  background-color: white;
  width: 100%;
  color: black;
  border-radius: 3px;
  overflow: hidden;
  z-index: 1000;

  transform: scaleY(0) scaleX(.5);
  opacity: 0;
  visibility: hidden;
  clip: rect(0,0,0,0)
  transform-origin: 50% 0;
  &.open {
    visibility: visible;
    clip: auto;
    top: 0;
    left: 0;
    opacity: 1;
    transition: transform .1s ease-out;
    transform: scaleY(1) scaleX(1);
    box-shadow: 5px 5px 15px rgba(0,0,0,.2);
  }
`;

interface DropdownProps {
  onChange: (value: string) => void;
  tabIndex: number | null | undefined;
  className: string | undefined;
  name: string;
  value: string | null;
  options: { id: string; value: string; label: string }[];
  placeholder?: string;
}

export const Dropdown = styled(
  class extends React.Component<DropdownProps> {
    state = { open: false };
    ref = React.createRef<HTMLDivElement>();
    toggleDropdown = (e: any) => {
      if (e) {
        e.preventDefault();
      }
      this.setState({ open: !this.state.open });
    };
    optionChanged = (e: React.FormEvent<HTMLInputElement>): void => {
      if (e.currentTarget.checked === true) {
        this.props.onChange(e.currentTarget.value);
      }
      this.setState({ open: false });
    };
    onWindowClick = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        this.ref.current &&
        !this.ref.current.contains(e.target)
      ) {
        this.setState({ open: false });
      }
    };
    componentDidMount() {
      window.addEventListener('click', this.onWindowClick);
    }
    componentWillUnmount() {
      window.removeEventListener('click', this.onWindowClick);
    }
    render() {
      const { placeholder, options, name, value, onChange, tabIndex, ...p } =
        this.props;
      let dropdownValue = '';
      if (value && value !== '') {
        let result = find(options, { value: value });
        if (result && result.label) {
          dropdownValue = result.label;
        }
      }
      return (
        <div role="radiogroup" ref={this.ref} {...p}>
          <DropdownButton
            tabIndex={tabIndex}
            placeholder={placeholder}
            value={dropdownValue}
            onMouseDown={this.toggleDropdown.bind(this)}
          />
          <DropdownOptions className={this.state.open ? 'open' : ''}>
            {options.map((option) => {
              return (
                <DropdownOption
                  key={option.value}
                  id={option.id}
                  value={option.value}
                  checked={option.value === value}
                  name={name}
                  onClick={(e) => {
                    this.setState({ open: false });
                  }}
                  onChange={(e) => {
                    this.optionChanged(e);
                  }}
                >
                  {option.label}
                </DropdownOption>
              );
            })}
          </DropdownOptions>
        </div>
      );
    }
  }
)`
  position: relative;
  &:focus {
    // padding: 8px 8px;
    border: 3px solid ${(props) => props.theme.secondaryColor};
    outline: none;
  }
`;
