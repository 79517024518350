import React from 'react';
import styled from 'styled-components';
import { Dropdown } from '../lib/components/dropdown';
import MyInput from './answer-input';

interface Answer {
  type: string;
  number: number;
}

interface TypeAnswer {
  type: string;
}

interface NumberAnswer {
  number: string;
}

interface Props {
  className: string | undefined;
  tabIndex: number | undefined;
  answer?: Answer;
  setAnswer: (answer: TypeAnswer | NumberAnswer) => void;
}
export class CommercialLuxuryBase extends React.Component<Props> {
  render() {
    const props = this.props;
    let options = [
      {
        id: 'commercial-luxury-commercial',
        value: 'commercial',
        label: 'Commercial',
      },
      {
        id: 'commercial-luxury-luxury',
        value: 'luxury',
        label: 'Luxury',
      },
    ];

    return (
      <div className={this.props.className}>
        <MyInput
          tabIndex={this.props.tabIndex || undefined}
          className="number-input main-input"
          value={this.props.answer && this.props.answer.number}
          type="number"
          min="0"
          step="1"
          pattern="[0-9]"
          onChange={(e: React.FormEvent<any>) => {
            if (e && e.currentTarget) {
              this.props.setAnswer({ number: e.currentTarget.value });
            }
          }}
        />
        <Dropdown
          tabIndex={this.props.tabIndex ? this.props.tabIndex + 1 : undefined}
          className="type-input"
          name="commercial-luxury-select"
          placeholder="Select a type..."
          value={props.answer && props.answer.type ? props.answer.type : null}
          options={options}
          onChange={(value) => {
            value && props.setAnswer({ type: value });
          }}
        />
      </div>
    );
  }
}

export default styled(CommercialLuxuryBase)`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  .number-input {
    width: 64px;
    float: left;
  }
  .type-input {
    width: 186px;
    float: right;
  }
`;
