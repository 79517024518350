import validateQuestion from './validate-question';
import remaxTextFilter from '../lib/remax-text-filter';

export function getCleanProps({ info, answers, questions } = {}) {
  let cleanedProps = { questions: [], info: info };
  info = { ...info };

  for (let i = 0; i < questions.length; ++i) {
    if (
      answers[questions[i].id] &&
      validateQuestion(questions[i].id, answers[questions[i].id])
    ) {
      cleanedProps.questions.push({
        animationName: questions[i].animationName,
        answer: { ...answers[questions[i].id] },
      });
    }
  }
  for (let i = 0; i < cleanedProps.questions.length; ++i) {
    let q = cleanedProps.questions[i].answer;
    for (let prop in q) {
      if (q.hasOwnProperty(prop) && typeof q[prop] === 'string') {
        q[prop] = remaxTextFilter(q[prop]);
      }
    }
  }
  for (let prop in info) {
    if (info.hasOwnProperty(prop)) {
      if (prop !== 'email') {
        info[prop] = remaxTextFilter(info[prop]);
      }
      if (prop === 'phoneNumber') {
        info[prop] = formatPhoneNumber(info[prop]);
      }
    }
  }
  cleanedProps.info = info;
  return cleanedProps;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }
  let num = phoneNumber;
  if (phoneNumber.length > 10 || phoneNumber[0] === '1') {
    let parts = phoneNumber.match(/(\d{0,1})(\d{0,3})(\d{0,3})(.*)/);
    if (parts && parts.length > 1) {
      num = parts[1];
      for (let i = 2; i < parts.length; ++i) {
        if (parts[i].length > 0) {
          num = num + '.' + parts[i];
        }
      }
    }
  } else {
    let parts = phoneNumber.match(/(\d{0,3})(\d{0,3})(.*)/);
    if (parts && parts.length > 1) {
      num = parts[1];
      for (let i = 2; i < parts.length; ++i) {
        if (parts[i].length > 0) {
          num = num + '.' + parts[i];
        }
      }
    }
  }
  return num;
}
