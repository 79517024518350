import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  winter: {
    ...baseStyle,
    isNew: true,
    name: 'Winter',
    thumbnail: '/images/mats/mat_winter-thumb.jpg',
    bgImage: '/images/mats/mat_winter-bg.jpg',
    overlayImage: '/images/mats/mat_winter-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'modesto-text, Georgia, serif',
      spacing: 0.27,
      pos: { x: 0.49, y: 705 / 1056 },
      maxWidth: 770 / 1280,
      fontColor: '#000',
      fontSize: 35 / 1280,
      // fontWeight: '800',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: 902 / 1056,
      },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: (902 + 48) / 1056,
      },
    },
  },
};

export default mats;
