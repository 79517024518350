// @flow
import React from 'react';
import { store } from '../app';
import { setAnswer, setInfo } from '../reducers/hustlegraphics';
import { Provider, connect } from 'react-redux';
import { PageHead } from '../lib/components/page-head';
import styled from 'styled-components';
import { PageContentInner } from '../lib/components/page-content';
import { FullWidthButton } from '../lib/components/buttons';
import InfoList from './info-list';
import { validateAll } from './validate-question';
import { QuestionList } from './question-list';
import { TREAT, COMMERCIAL_LUXURY, SONG } from '../reducers/hustlegraphics';
import Analytics, { GA_CATEGORY_HUSTLEGRAPHIC } from '../analytics';
import questions from './questions-data';

const PageContentCustom = styled((props) => (
  <div {...props}>
    <PageContentInner>{props.children}</PageContentInner>
  </div>
))`
  margin-left: auto;
  margin-right: auto;
  padding-top: ${(props) => props.theme.headerHeight}px; /* .header -> height */
  width: ${(props) => props.theme.margin * 2 + props.theme.questionWidth}px;
  ${(props) => props.theme.mq1} {
    width: ${(props) => props.theme.margin2 * 2 + props.theme.questionWidth}px;
  }
  ${(props) => props.theme.questionMQ1} {
    width: ${(props) =>
      props.theme.questionWidth * 2 +
      props.theme.margin * 1 +
      props.theme.margin2 * 2}px;
  }
  ${(props) => props.theme.mq2} {
    width: ${(props) =>
      props.theme.questionWidth * 2 +
      props.theme.margin * 1 +
      props.theme.margin3 * 2}px;
  }
  ${(props) => props.theme.questionMQ2} {
    width: ${(props) =>
      props.theme.questionWidth * 3 +
      props.theme.margin * 4 +
      props.theme.margin3 * 2}px;
  }
  // ${(props) => props.theme.questionMQ3} {
  //   width: ${(props) =>
    props.theme.questionWidth * 4 +
    props.theme.margin * 2 +
    props.theme.margin3 * 2}px;
  // }
`;

const ErrorWrap = styled.div`
  display: block;
  border-radius: 7px;
  max-width: 600px;
  margin: 20px auto;
  overflow: hidden;
  border: 3px solid ${({ theme } = {}) => theme.secondaryColorLight};
  h2 {
    margin: 0 0 0px 0;
    padding: 8px 20px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    background-color: ${({ theme } = {}) => theme.secondaryColorLight};
    color: white;
  }
  .errors {
    padding: 0 20px;
  }
  .error {
    text-align: center;
    margin-bottom: 12px;
    margin-top: 12px;
  }
`;

const ErrorDisplay = ({ errors = [] } = {}) => {
  if (!errors || errors.length <= 0) {
    return null;
  }
  return (
    <ErrorWrap>
      <h2>Please fix the following issues to continue</h2>
      <div className="errors">
        {errors.map((error, key) => (
          <div className="error" key={key}>
            {error.message}
          </div>
        ))}
      </div>
    </ErrorWrap>
  );
};

class QuestionSelectPage extends React.Component {
  state = {
    submitAttempted: false,
  };
  // props: {
  //   answers: Answers,
  //   info: Info,
  //   setAnswer: (string, Answer) => void,
  //   setInfo: (string, string) => void,
  // };
  logAnswer = (answer, question) => {
    let analyticsLabel = question.analyticsLabel;
    let label;
    if (question.id === COMMERCIAL_LUXURY) {
      if (
        !answer.number ||
        answer.number === '' ||
        Number(answer.number) === 0
      ) {
        return;
      }
      if (answer.type === 'commercial') {
        analyticsLabel = 'Commercial Properties';
      } else if (answer.type === 'luxury') {
        analyticsLabel = 'Collection Properties';
      } else {
        return;
      }
    } else if (question.id === TREAT) {
      label = answer.treat;
      if (!answer.treat || answer.treat === '') {
        return;
      }
    } else if (question.id === SONG) {
      label = answer.songTitle;
      if (!answer.songTitle || answer.songTitle === '') {
        return;
      }
    } else {
      label = answer.number;
      if (
        !answer.number ||
        answer.number === '' ||
        Number(answer.number) === 0
      ) {
        return;
      }
    }
    let e = {
      category: GA_CATEGORY_HUSTLEGRAPHIC,
      action: `Answered: ${analyticsLabel}`,
      label: label,
    };
    Analytics.event(e);
  };
  logAnswers = () => {
    let answer;
    questions.forEach((question) => {
      answer = this.props.answers[question.id];
      if (answer) {
        this.logAnswer(answer, question);
      }
    });
  };
  onSubmitAttempt = (e) => {
    const errors = validateAll({
      info: this.props.info,
      answers: this.props.answers,
    });
    if (errors.length > 0) {
      this.setState({ submitAttempted: true });
      if (e) {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
      }
    } else {
      this.logAnswers();
    }
  };
  render() {
    const errors = validateAll({
      info: this.props.info,
      answers: this.props.answers,
    });
    const showErrors = errors.length > 0 && this.state.submitAttempted;
    return (
      <PageContentCustom>
        <PageHead>
          Tell us about your
          <br />
          last 12 months
        </PageHead>
        <QuestionList
          isPage={true}
          answers={this.props.answers}
          setAnswer={this.props.setAnswer}
        />
        <PageHead>Enter your information</PageHead>
        <InfoList info={this.props.info} setInfo={this.props.setInfo} />
        {showErrors ? <ErrorDisplay errors={errors} /> : null}
        <FullWidthButton
          onClick={this.onSubmitAttempt}
          disabled={showErrors}
          tabIndex="1000"
          to="/hustlegraphic/view"
        >
          Build my Hustlegraphic
        </FullWidthButton>
      </PageContentCustom>
    );
  }
}

const QuestionSelectPageContainer = connect(
  (state, ownProps) => ({
    ...{},
    ...ownProps,
    info: state.hustlegraphics.info,
    answers: state.hustlegraphics.answers,
  }),
  { setInfo, setAnswer }
)(QuestionSelectPage);

export default (props) => (
  <Provider store={store}>
    <QuestionSelectPageContainer />
  </Provider>
);
